
import { Container } from "react-bootstrap"
import { BLAButton } from "../../components/BLAButton"
import { useNavigate } from "react-router-dom"
import { useState } from 'react';

import Carousel from 'react-multi-carousel'
import leftArrow from "../../assets/img/LeftArrow.svg"
import rightArrow from "../../assets/img/RightArrow.svg"
import leftArrowWhite from "../../assets/img/LeftArrowWhite.svg"
import rightArrowWhite from "../../assets/img/RightArrowWhite.svg"

// Icones Content
import icon_content_captacao from "../../assets/img/Icon_Content_Captacao.svg"
import icon_content_prototipos from "../../assets/img/Icon_Content_Prototipos.svg"
import icon_content_design from "../../assets/img/Icon_Content_Design.svg"
import icon_content_motion from "../../assets/img/Icon_Content_Motion.svg"

// Icones Strategy
import icon_strategy_planos from "../../assets/img/Icon_Strategy_Planos.svg"
import icon_strategy_social_media from "../../assets/img/Icon_Strategy_Social_Media.svg"
import icon_strategy_seo from "../../assets/img/Icon_Strategy_SEO.svg"
import icon_strategy_branding from "../../assets/img/Icon_Strategy_Branding.svg"

import "react-multi-carousel/lib/styles.css";

export const Servicos = () => {

    const [activeContentSlide, setActiveContentSlide] = useState(0);
    const [activeStrategySlide, setActiveStrategySlide] = useState(0);


    const sliderContent = [
        {
            url: icon_content_captacao,
            name1: "Captação e Edição de",
            name2: "Imagem e Som",
        },
        {
            url: icon_content_prototipos,
            name1: "Protótipos de Website",
            name2: "",
        },
        {
            url: icon_content_design,
            name1: "Design Gráfico",
            name2: "",
        },
        {
            url: icon_content_motion,
            name1: "Motion Graphics",
            name2: "",
        }
    ]

    const sliderStrategy = [
        {
            url: icon_strategy_planos,
            name: "Planos de Comunicação",
        },
        {
            url: icon_strategy_social_media,
            name: "Gestão de Social Media",
        },
        {
            url: icon_strategy_seo,
            name: "Estratégias de SEO",
        },
        {
            url: icon_strategy_branding,
            name: "Branding",
        }

    ]
    
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1,
        },
        tablet: {
          breakpoint: { max: 1024, min: 768 },
          items: 1,
        },
        mobile: {
          breakpoint: { max: 767, min: 100 },
          items: 1,
        }
      };

    const navigate = useNavigate()

    const handlePortfolioClick = () => {
        navigate('/wip')
    }

    const handleContactsClick = () => {
        navigate('/wip')
    }

    const contentDescriptions = [
        "Disponibilizamos serviços audiovisuais, como fotografia, gravação e edição de vídeos promocionais, institucionais ou cobertura de eventos e muito mais.",
        "Criamos a estética do seu site de acordo com os seus objetivos de negócio. Visualize o futuro do seu site com protótipos inovadores.",
        "Fazemos Branding e Rebranding dos mais variados tipos de negócio e produzimos conteúdos para meios online e offline. A criação de manual de normas gráficas, logotipo, templates para redes sociais e panfletos, são alguns exemplos.", 
        "Desenvolvemos peças de comunicação animadas, dinâmicas e criativas, para projetos como vídeos institucionais e de apresentação de serviços."
    ];
    
    const strategyDescriptions = [
        "Realizamos uma análise interna à sua marca e, consoante os resultados obtidos, desenvolvemos as táticas mais adequadas para as suas necessidades, de forma a ganhar mais notoriedade e a atingir o seu público-alvo.",
        "Com base nos objetivos e públicos-alvo, desenvolvemos um plano de redes sociais completo, com criação do conteúdo visual, escrito e respetiva calendarização.",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris.",
        "Partindo da missão, visão e valores, damos vida a vários tipos de negócios, tendo sempre em conta a sua essência."
    ];

    return (
        <div className="servicos">
        <Container className="pb-50">
            <h2 className="super-title-over">OS NOSSOS</h2>
            <h2 className="super-title-under">SERVIÇOS</h2>
            <p>
            Transformamos ideias em resultados! Conheça os nossos serviços e descubra como podemos ajudar a sua marca a brilhar. 
            </p>

            <h3 className="title">CONTENT</h3>
            <p>
            Criamos conteúdos personalizados para destacar a essência da sua marca em todos os canais digitais e offline. Conectamos a sua marca ao público certo com estratégias criativas e relevantes.
            </p>

            <Carousel
                id="content-carousel"
                additionalTransfrom={0}
                arrows
                centerMode={false}
                 initialSlide={0}
                 responsive={responsive}
                 autoPlay={false}
                 swipeable={true}
                 draggable={true}
                 showDots={false}
                 infinite={false}
                 partialVisible={false}
                 customLeftArrow={<img className="slider-arrow slider-arrow--left" src={leftArrow} alt="leftArrow" />}
                 customRightArrow={<img className="slider-arrow slider-arrow--right" src={rightArrow} alt="rightArrow" />}
                 dotListClass=""
                 focusOnSelect={false}
                 itemClass=""
                 keyBoardControl
                 minimumTouchDrag={80}
                 pauseOnHover
                 renderArrowsWhenDisabled={false}
                 renderButtonGroupOutside={true}
                 beforeChange={(nextSlide) => setActiveContentSlide(nextSlide)}
                >

                {sliderContent.map((content, index) => (
                    <div className="slider" key={index}>
                        <img src={content.url} alt="content" />
                        <p className="slider-servicos-title">{content.name1}<br/>{content.name2}</p>
                    </div>
                ))}

                </Carousel>
            <p className="mt-30">
                {contentDescriptions[activeContentSlide]}
            </p>
            <p className="cta mb-30 mt-50">Vem descobrir os nossos <br/>trabalhos brilhantes!</p>
            <BLAButton text="Portfólio" onClick={handlePortfolioClick} />
        </Container>
        <Container className="dark-container pb-50">
            <h3 className="title">STRATEGY</h3>
            <p>
            Planeamos estratégias personalizadas para posicionar a sua marca no caminho do sucesso. Combinamos criatividade e análise para criar planos de comunicação que conectam e convertem.
            </p>

            <Carousel
            id="strategy-carousel"
            additionalTransfrom={0}
                initialSlide={0}
                responsive={responsive}
                autoPlay={false}
                swipeable={true}
                draggable={true}
                showDots={false}
                infinite={false}
                partialVisible={false}
                customLeftArrow={<img className="slider-arrow slider-arrow--left" src={leftArrowWhite} alt="leftArrow" />}
                customRightArrow={<img className="slider-arrow slider-arrow--right" src={rightArrowWhite} alt="rightArrow" />}
                dotListClass=""
                focusOnSelect={false}
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={true}
                centerMode = {false}
                beforeChange={(nextSlide) => setActiveStrategySlide(nextSlide)}
                >

                {sliderStrategy.map((strategy, index) => (
                    <div className="slider" key={index}>
                        <img src={strategy.url} alt="strategy" />
                        <p className="slider-servicos-title">{strategy.name}</p>
                    </div>
                ))}

                </Carousel>
            <p className="mt-30">
                {strategyDescriptions[activeStrategySlide]}
            </p>
            <p className="cta mb-30 mt-50">Se ainda tiveres dúvidas <br/>entra em contacto connosco</p>
            <BLAButton variant="dark" text="Contactos" onClick={handleContactsClick} />
        </Container>
        </div>
    )
}