
import { Container } from "react-bootstrap"
import { BLAButton } from "../../components/BLAButton"
export const OQueFazemosAU = () => {
    return (
        <Container className="dark-container">
            <h3 className="title">O QUE FAZEMOS</h3>
            <h4 className="mb-30">Desenvolvemos porjetos nas áreas de Content e Strategy:</h4>
            <p className="mb-30">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.</p>
            <BLAButton text="Saber mais" variant="dark"/>
        </Container>
    )
}