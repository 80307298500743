
import { Accordion, Container } from "react-bootstrap"
import { BLAButton } from "../../components/BLAButton"
import { useNavigate } from "react-router-dom"

export const Recrutamento = () => {

    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/aboutus')
    }
    return (
            <div className="recrutamento">
            <Container className="dark-container pb-50">
                <h2 className="super-title-over">O NOSSO</h2>
                <h2 className="super-title-under-recrutamento">RECRUTAMENTO</h2>
                <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris.
                </p>
                <p className="cta mb-30 mt-50 cta-recrutamento">Enquanto o recrutamento não<br/>abre, aprende mais acerca da bla</p>
                <BLAButton variant="dark" text="Sobre Nós" onClick={handleClick} />
            </Container>
            <Container>
                <h3 className="title">Processo</h3>
                <p>
                O nosso processo de recrutamento é dividido em 4 fases, que ocorrem no início de cada semestre. Cada etapa é projetada para avaliar as tuas habilidades e o teu potencial de integração na nossa equipa.
                </p>
                <h4 className="dark-subtitle mt-40">Fases</h4>
                <div className="list">
                    <ol>
                        <li>
                            <b>Formulário de Inscrição</b><br/>
                            Preenche o formulário com os teus dados e escolhe o departamento que mais te interessa.
                        </li>
                        <li>
                            <b>Dinâmica de grupo</b><br/>
                            Nesta fase, irás participar em atividades e desafios de grupo, onde poderás demonstrar o teu espírito de equipa e ver como te adaptas a trabalhar com a equipa.
                        </li>
                        <li>
                            <b>Briefing Individual</b><br/>
                            Resolverás um desafio personalizado, relacionado com o departamento ao qual te inscreveste. Este é o momento para mostrares o teu conhecimento e criatividade.
                        </li>
                        <li>
                            <b>Entrevista Individual</b><br/>
                            A última etapa é uma conversa individual, onde ficamos a conhecer um pouco mais sobre ti e por que acreditas ser a pessoa ideal para te juntares à nossa equipa.
                        </li>
                    </ol>
                </div>
            </Container>
            <Container className="dark-container pb-50">
                <h3 className="title">FAQs</h3>
                
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Lorem ipsum dolor sit amet  <br/> consectetur adipiscing.</Accordion.Header>
                        <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Lorem ipsum dolor sit amet  <br/> consectetur adipiscing.</Accordion.Header>
                        <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Lorem ipsum dolor sit amet  <br/> consectetur adipiscing.</Accordion.Header>
                        <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Lorem ipsum dolor sit amet  <br/> consectetur adipiscing.</Accordion.Header>
                        <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Container>
        </div>
    )
}