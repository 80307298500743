// CSS Imports
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


// Components Import
import { NavBar } from './view/sections/Navbar';
import { Footer } from './view/sections/Footer';
import { Homepage } from './view/mainpages/Homepage';
import { AboutUs } from './view/mainpages/AboutUs';
//import { MovimentoJunior } from './view/mainpages/MovJunior';
import { Servicos } from './view/mainpages/Servicos';
import { WIP } from './view/mainpages/WIP';
import { DesktopWIP } from './view/mainpages/DesktopWIP';
import { Recrutamento } from './view/mainpages/Recrutamento';
import { Contacts } from './view/mainpages/Contacts';
import  ScrollToTop  from './components/ScrollToTop';


function DesktopRedirect() {
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth > 768 && location.pathname !== '/desktop-wip') {
        navigate('/desktop-wip');
      }
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    
    return () => window.removeEventListener('resize', checkScreenSize);
  }, [navigate, location]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <DesktopRedirect />
        <NavBar/>
        <Routes>
          <Route path="/" element={<Homepage/>}/>
          <Route path="/aboutus" element={<AboutUs/>}/>
          <Route path="/servicos" element={<Servicos/>}/>
          <Route path="/recrutamento" element={<Recrutamento/>}/>
          <Route path="/wip" element={<WIP/>}/>
          <Route path="/desktop-wip" element={<DesktopWIP/>}/>
          <Route path="/contacts" element={<Contacts/>}/>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}


export default App;
