
import { Container, Row, Col } from "react-bootstrap"
export const ContadorHome = () => {
    return (
        <Container className="dark-container counter">
            <Row>
                <Col xs={3} className="counter-cell">
                    <Row className="counter-cell-title">
                        Membros
                    </Row>
                    <Row className="counter-cell-value">
                       50+
                    </Row>
                </Col>
                <Col xs={3} className="counter-cell">
                    <Row className="counter-cell-title">
                        Projetos Realizados
                    </Row>
                    <Row className="counter-cell-value">
                        40
                    </Row>
                </Col>
                <Col xs={3} className="counter-cell">
                    <Row className="counter-cell-title">
                        Anos de existência
                    </Row>
                    <Row className="counter-cell-value">
                        8
                    </Row>
                </Col>
                <Col xs={3} className="counter-cell">
                    <Row className="counter-cell-title">
                        Avaliação de clientes
                    </Row>
                    <Row className="counter-cell-value">
                        9/10
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}