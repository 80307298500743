
import { Accordion, Container } from "react-bootstrap"
import { BLAButton } from "../../components/BLAButton"
import { useNavigate } from "react-router-dom"

export const FAQs = () => {

    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/contacts')
    }

    return (
        <Container className="faq">
            <h3 className="title">FAQs</h3>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Não tenho muita experiência, <br/> posso candidatar-me?</Accordion.Header>
                    <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Sou estudante de Erasmus, posso<br/>candidatar-me?</Accordion.Header>
                    <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>É possível pertencer à BLA e<br/> trabalhar ao mesmo tempo?</Accordion.Header>
                    <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <p className="cta mb-30 mt-50">Se ainda tiveres dúvidas <br/>entra em contacto connosco</p>
            <BLAButton text="Contactos" onClick={handleClick} />
        </Container>
    )
}