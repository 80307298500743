
import { Container } from "react-bootstrap"
import { BLAButton } from "../../components/BLAButton"
import { useNavigate } from "react-router-dom"

export const OQueFazemos = () => {

    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/servicos')
    }

    return (
        <Container className="dark-container">
            <h3 className="title">O QUE FAZEMOS</h3>
            <h4>Captação e edição de vídeo, fotografia e áudio</h4>
            <p className="mb-40">Fotografamos, gravamos e editamos – uma solução audiovisual integrada, com serviços de produção e pós-produção. </p>
            <h4>Design Gráfico</h4>
            <p className="mb-40">Criamos a identidade gráfica da sua marca, desenvolvemos conteúdo gráfico para online e offline – em poucas palavras damos vida à ideia que não sai da sua cabeça.
            </p>
            <h4>Motion Graphics</h4>
            <p className="mb-30">Desenvolvemos peças de comunicação animadas,
            criando uma narrativa dinâmica e criativa que gera maior impacto visual.</p>
            <BLAButton text="Serviços" variant="dark" onClick={handleClick}/>
        </Container>
    )
}