import { BLAButton } from "../../components/BLAButton"
import backgroundDestaque from "../../assets/img/Background_Loop_Destaque.svg"
import { useNavigate } from "react-router-dom"

export const Banner = () => {

    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/servicos')
    }
    
    return (
        <>
            <div className="banner">
                <div className="banner-background">
                    <img src={backgroundDestaque} alt="" className="background-destaque" />
                    <div className="background-pattern"></div>
                </div>
                <BLAButton text="Vê os nossos serviços!" onClick={handleClick} />
                <div className="circle-line-container">
                    <div className="white-wavy-line"></div>
                    <div className="white-wavy-line-2"></div>
                    <div className="yellow-wavy-line"></div>
                    <div className="yellow-circle"></div>
                    <div className="vertical-line"></div>
                    <div className="black-triangle-circle"></div>
                    <div className="black-triangle"></div>
                </div>
                <div className="banner-bottom-triangle"></div>
            </div>
        </>
    )
}