
import { Container } from "react-bootstrap"
import Carousel from 'react-multi-carousel'
import { useState } from 'react';

import leftArrow from "../../assets/img/LeftArrow.svg"
import rightArrow from "../../assets/img/RightArrow.svg"

import avatar1 from "../../assets/img/avatars/1.png"
import avatar2 from "../../assets/img/avatars/2.png"
import avatar3 from "../../assets/img/avatars/3.png"
import avatar4 from "../../assets/img/avatars/4.png"
import avatar5 from "../../assets/img/avatars/5.png"
import avatar6 from "../../assets/img/avatars/6.png"
import "react-multi-carousel/lib/styles.css";

const sliderAvatars = [
    {
        url: avatar1,
        name: "João Silva",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna."
    },
    {
        url: avatar2,
        name: "Maria Silva",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna."
    },
    {
        url: avatar3,
        name: "Pedro Silva",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna."
    },
    {
        url: avatar4,
        name: "Joana Silva",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna."
    },
    {
        url: avatar5,
        name: "Vítor Silva",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna."
    },
    {
        url: avatar6,
        name: "Carlos Silva",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna."
    },
]

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 3
    },
    mobile: {
      breakpoint: { max: 767, min: 100 },
      items: 1,
      slidesToSlide: 1
    }
  };

  const alumniDescriptions = [
    {
        name: "Em breve!",
        role: "",
        description: ""
    },
    {
        name: "Em breve!",
        role: "",
        description: ""
    },
    {
        name: "Em breve!",
        role: "",
        description: ""
    },
    {
        name: "Em breve!",
        role: "",
        description: ""
    },
    {
        name: "Em breve!",
        role: "",
        description: ""
    },
    {
        name: "Em breve!",
        role: "",
        description: ""
    }
];

export const Alumni = () => {
    
    const [activeAlumni, setActiveAlumni] = useState(0);


    return (
        <Container className="section-alumni">
            <h3 className="title">OS NOSSOS ALUMNI</h3>
            <Carousel
                responsive={responsive}
                autoPlay={false}
                swipeable={true}
                draggable={true}
                showDots={false}
                infinite={true}
                partialVisible={false}
                customLeftArrow={<img className="slider-arrow slider-arrow--left" src={leftArrow} alt="leftArrow" />}
                customRightArrow={<img className="slider-arrow slider-arrow--right" src={rightArrow} alt="rightArrow" />}
                dotListClass=""
                focusOnSelect={false}
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                centerMode = {true}
                beforeChange={(nextSlide) => setActiveAlumni(nextSlide % alumniDescriptions.length)}
                >

                {sliderAvatars.map((alumni, index) => (
                    <div className="slider" key={index}>
                        <img src={alumni.url} alt="alumni" />
                    </div>
                ))}

                </Carousel>

                <div className="alumni-description">
                    <h4 className="alumni-description-name">{ alumniDescriptions[activeAlumni]["name"] }</h4>
                    <p className="alumni-description-role">{ alumniDescriptions[activeAlumni]["role"] }</p>
                    <p className="alumni-description-text">{ alumniDescriptions[activeAlumni]["description"]}</p>
                </div>
        </Container>
    )
}