
import { Container } from "react-bootstrap"
import { BLAButton } from "../../components/BLAButton"
import callRecrutamento from "../../assets/img/CallRecrutamento.svg"
import { useNavigate } from "react-router-dom"

export const CallRecrutamento = () => {

    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/recrutamento')
    }

    return (
        <Container className="dark-container">
            <img className="mb-30 pt-50" src={callRecrutamento} alt="CallRecrutamento" />
            <BLAButton text="Recrutamento" variant="dark" onClick={handleClick}/>
        </Container>
    )
}