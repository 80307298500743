import { Col, Container, Row } from "react-bootstrap"
import iconLinkedIn from "../../assets/img/icon_linkedin.svg"
import iconInstagram from "../../assets/img/Instagram.svg"

export const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className="align-items-center">
                    <Col className="text-start">
                        <p>2024 &#174; Bright Lisbon Agency</p>
                    </Col>
                    <Col className="social-icons" xs={3}>
                        <div className="social-icon">
                            <a target="_blank" rel="noreferrer" href='https://www.linkedin.com/company/brightlxagency/'><img src={iconLinkedIn} alt="LinkedIn"/></a>
                        </div>
                        <div className="social-icon">
                            <a target="_blank" rel="noreferrer" href='https://www.instagram.com/brightlxagency/'><img src={iconInstagram} alt="Instagram"/></a>
                    </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}