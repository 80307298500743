
import { Container } from "react-bootstrap"
import { BLAButton } from "../../components/BLAButton"
import { useNavigate } from "react-router-dom"

export const QuemSomos = () => {

    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/aboutus')
    }


    return (
        <Container className="quem-somos">
            <h3 className="title">QUEM SOMOS</h3>
            <p>
                A Bright Lisbon Agency foi a primeira Júnior Empresa na área da Comunicação em Portugal e é constituída exclusivamente por alunos da Escola Superior de Comunicação Social.
            </p>
            <p className="mb-30">
                Com o apoio do nosso conselho consultivo e alumni* presentes no mercado de trabalho, a BLA procura oferecer diversos serviços de excelência na área da Comunicação.
            </p>

            <BLAButton text="Descobre Mais" onClick={handleClick} />
        </Container>
    )
}