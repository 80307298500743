import { Alumni } from "../sections/Alumni"
import { Historia } from "../sections/Historia"
import { OQueFazemosAU } from "../sections/OQueFazemosAU"
import { Parceiros } from "../sections/Parceiros"

export const AboutUs = () => {
    return (
        <div className="about-us">
            <Historia/>
            <Alumni />
            <OQueFazemosAU/>
            <Parceiros/>
        </div>
    )
}