// Imports
import {Button} from  'react-bootstrap'

export const BLAButton = (props) => {
    return (
        <Button 
            type={props.type === "submit" ? "submit" : "button"} 
            className={props.variant === "dark" ? "dark" : ""} 
            value={props.text}
            onClick={props.onClick}

            style={props.isLarger ? {width: "75%"} : {}}
            >{props.text}
            
            </Button>
    )
}
