// File Imports
import logo from "../../assets/img/logo.svg"
import iconClose from "../../assets/img/icon_close.svg"

// Imports
import {Container, Nav, Navbar} from  'react-bootstrap'
import {useEffect, useState, useRef} from 'react'
import {Link} from 'react-router-dom'

export const NavBar = () => {
    // Navbar opening control, to fix closing outside bug
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const warpNavBar = useRef("navbar")
    const warpNavbarToggle = useRef("navbarToggle")

    const handleToggle = () => setIsNavbarOpen(!isNavbarOpen);

    const handleClose = () => setIsNavbarOpen(false);

    const [activeLink, setActiveLink] = useState("home");

    const onUpdateActiveLink = (linkValue) => {
        setActiveLink(linkValue)

        setIsNavbarOpen(false)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!warpNavBar?.current?.contains(event.target)) {
                if (warpNavbarToggle?.current?.contains(event.target)) {
                    handleToggle()
                } else {
                    handleClose();
                }
               
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return() => document.removeEventListener("mousedown", handleClickOutside)
    });

    return (
        <Navbar collapseOnSelect expand="md" fixed="top">
            <Container>
                <Navbar.Brand href="#sobre-nos"><Link to="/"><img src={logo} alt="Bright Lisbon Agency"></img></Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" ref={warpNavbarToggle}>
                <span className='navbar-toggler-icon'></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav" onExited={handleClose} in={isNavbarOpen} appear={isNavbarOpen} ref={warpNavBar}>
                <button className="close-btn" onClick={handleClose}>
                    <img src={iconClose} alt="Close menu"></img>
                </button>
                    <Nav>
                        <Link to="/aboutus"><Nav.Link eventKey="sobre-nos" href="about-us" className={activeLink === 'sobre-nos' ? "active navbar-link" : "navbar-link"} onClick={() => onUpdateActiveLink("sobre-nos")}>SOBRE NÓS</Nav.Link></Link>
                        <Link to="/wip"><Nav.Link eventKey="mov-junior" href="wip" className={activeLink === 'mov-junior' ? "active navbar-link" : "navbar-link"} onClick={() => onUpdateActiveLink("mov-junior")}>MOVIMENTO JÚNIOR</Nav.Link></Link>
                        <Link to="/wip"><Nav.Link eventKey="equipa" href="wip" className={activeLink === 'equipa' ? "active navbar-link" : "navbar-link"} onClick={() => onUpdateActiveLink("equipa")}>EQUIPA</Nav.Link></Link>
                        <Link to="/servicos"><Nav.Link eventKey="serviços" href="servicos" className={activeLink === 'serviços' ? "active navbar-link" : "navbar-link"} onClick={() => onUpdateActiveLink("serviços")}>SERVIÇOS</Nav.Link></Link>
                        <Link to="/wip"><Nav.Link eventKey="portfolio" href="wip" className={activeLink === 'portfolio' ? "active navbar-link" : "navbar-link"} onClick={() => onUpdateActiveLink("portfolio")}>PORTFÓLIO</Nav.Link></Link>
                        <Link to="/recrutamento"><Nav.Link eventKey="recrutamento" href="recrutamento" className={activeLink === 'recrutamento' ? "active navbar-link" : "navbar-link"} onClick={() => onUpdateActiveLink("recrutamento")}>RECRUTAMENTO</Nav.Link></Link>
                        <Link to="/contacts"><Nav.Link eventKey="contacts" href="contacts" className={activeLink === 'contacts' ? "active navbar-link" : "navbar-link"} onClick={() => onUpdateActiveLink("contacts")}>CONTACTOS</Nav.Link></Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
