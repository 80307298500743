import { Banner } from "../sections/Banner"
import { CallRecrutamento } from "../sections/CallRecrutamento"
import { ContadorHome } from "../sections/ContadorHome"
import { FAQs } from "../sections/FAQs"
import { OQueFazemos } from "../sections/OQueFazemos"
import { QuemSomos } from "../sections/QuemSomos"
export const Homepage = () => {
    return (
        <div className="homepage">
            <Banner/>
            <QuemSomos/>
            <ContadorHome/>
            <OQueFazemos/>
            <FAQs/>
            <CallRecrutamento/>
        </div>
    )
}